// Navbar Change on Scroll
@import "variables.less";
@import "mixins.less";

@media(min-width:800px) {
  li {
      a {font-size:14px;}
      }
            
}
@media(min-width:768px) {
    .navbar-custom.affix {
        background-color: @gray-darker;
        padding: 10px 0;
        .navbar-brand {
            font-size: 1.5em;
        }
     //bc///////////////////
         li {
            a {
                .heading-font;
                font-weight: bold;
                letter-spacing: 1px;
                color: #14272b;
                &:hover,
                &:focus {
                    color: #ffa700;
                    outline: none;
                }
            }
        }
     //bc////////////////////////////
        }
  
  .navbar-custom.affix-top{
 
    .nav {
         li {
            a {
                .heading-font;
                font-weight: bold;
                letter-spacing: 1px;
                color: white;
                &:hover,
                &:focus {
                    color: #ffa700;
                    outline: none;
                }
            }
            //a.except{color:white;}
        }
    }
    //bc////////////////////////////
    }
}

//bc///////////////////
.navbar-custom{
    .nav {
         li {
            a {
                 
                .heading-font;
                font-size:11px;
                font-weight: bold;
                letter-spacing: 1px;
                color: #010180;
                &:hover,
                &:focus {
                    color: #ffa700;
                    outline: none;
                }
            }
            a.except{color:#ffa700;}
        }
    }

}
 //bc////////////////////////////