//bc////////////////////////////
@import "variables.less";
@import "mixins.less";
.page-scroll img
{
    margin-top: -9px;
    height: 200%;
}

.page-scroll #brand-text
{
    font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, cursive;
    font-size:70%; 
    margin-top: -2em; 
    margin-left: 5em;
}

@media(min-width:768px) and (max-width: 800px) 
{
    .navbar-default.affix .navbar-brand img
        {
        margin-top: -15px;
        margin-left: 0px;
        height: 170%;
    }
    .navbar-default .navbar-brand img
       {
       margin-left: -20px;
       margin-top: -30px;
        height: 250%;
        -webkit-transition: all .3s;
        -moz-transition: all .3s;
        transition: all .3s;
    }

    .navbar-default .navbar-brand #brand-text
        {
             
            margin-left: 40px;
            font-size:70%; 
            
     }
    .navbar-default.affix .navbar-brand #brand-text
        {
            color:#000080; 
            font-size:60%; 
            
    }
   
}
@media(min-width:801px) {

     .navbar-default.affix .navbar-brand img
        {
        margin-top: -16px;
        margin-left: -4px;
        height: 200%;
    }
   
    .navbar-default .navbar-brand img
       {
       margin-left: -50px;
       margin-top: 0px;
        height: 800%;
        -webkit-transition: all .3s;
        -moz-transition: all .3s;
        transition: all .3s;
    }
        
     .navbar-default .navbar-brand #brand-text
        {
         color:#000080; 
          
           margin-left: -4px;
           font-size:80%; 
            margin-top: 0px; 
            
     }
     
     .navbar-default.affix .navbar-brand #brand-text
        {
            
            font-size: 60%;
            margin-top: 0.3em;
            margin-left: -13px;
    }

}